import {Di} from "hind/core";
import View from "../Controllers/View";

export default class Hooks {
    hook_before_app_loading() {
        /*
            EXEMPLE IF Progress_bar is intalled
            Di.get_di("Progress_bar").start();
         */

        return new Promise(
            (resolve, reject) => {
                resolve(true);
            }
        );
    }

    hook_before_first_loading_template_transition() {
        //console.log("Actions - hook_before_first_loading_template_transition");
        return new Promise(
            (resolve, reject) => {
                resolve(true);
            }
        );
    }

    hook_before_change_route_transition(Oroute, Onext_url, Oa) {
        //console.log("Actions - hook_before_change_route_transition");

        /*
            EXEMPLE IF Progress_bar is intalled
            Di.get_di("Progress_bar").start();
         */

        return new Promise(
            (resolve, reject) => {
                resolve(true);
            }
        );
    }

    hook_before_change_route_transition_always() {

    }

    hook_change_route_pending_transition(before_data, Ocurrent_url_info, Oa) {
        //console.log("Actions - hook_change_route_pending_transition");
        return new Promise(
            (resolve, reject) => {
                resolve(true);
            }
        );
    }

    hook_after_route_transition(view_data) {
        // console.log(view_data.Oroute._uri_info);

        /*
            EXEMPLE IF Progress_bar is intalled
            Di.get_di("Progress_bar").stop();
         */
    }

    destroy() {

    }
}