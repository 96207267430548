import env from "./env";

export default function app() {
    return {
        "protocol_app" : env().protocol,
        "dns_app"      : env().dns,
        "url_assets"   : "http://cv.local/assets/dist",
        "url_documents": "http://cv.local/assets/documents/",
        "port_app"     : `:3000`
    };
}