export default class Front {
    static get_launcher() {
        return {};
    }

    static get_modules() {
        return {
            front_firstly: [],
            front        : [
                {
                    module          : () => {
                        return import('../../Modules/API/AJAX/Requests')
                    },
                    di_key          : "AJAX_api",
                    is_a_hind_module: true,
                    config          : {
                        main_endpoint     : "api",
                        route_to_get_token: "token"
                    }
                }
            ]
        };
    }
}