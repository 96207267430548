import View from "../Controllers/View";

export default class Listeners {
    init() {
        this.open_legal_notice();
        this.close_legal_notice();
        this.do_keyboard_event();
    }

    open_legal_notice() {
        document.getElementById("footer__links__legal_notice").addEventListener("click", (evt) => {
            View.prevent_scrollable_body();
            View.open_legal_notice();
        }, false);
    }

    close_legal_notice() {
        document.getElementById("layer").addEventListener("click", (evt) => {
            View.do_scrollable_body();
            View.close_legal_notice();
        }, false);
        document.getElementById("legal_notice__close_button").addEventListener("click", (evt) => {
            View.do_scrollable_body();
            View.close_legal_notice();
        }, false);
    }

    do_keyboard_event() {
        document.onkeydown = (e) => {
            if (e.key === "Escape") {
                View.do_scrollable_body();
                View.close_legal_notice();
            }
        };
    }

    destroy() {
    }
}