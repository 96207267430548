import {Ohelper_view_assets as assets} from "hind/core";
import {Config_view} from "hind//workspace";
import {get_external_link} from "../utils/icon";

const get_block_in_waiting_of = () => {
    return `
        <div id="newsletter_layer" class="newsletter-layer"></div>
        <svg id="newsletter_spinner" class="newsletter-spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"/></svg>           
    `;
};

const is_resent_confirmation_link_error = () => {
    return `
        <div class="newsletter-subscription newsletter-subscription-confirmation-link-resent-with-error newsletter-subscription-response">
            ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
            <p class="newsletter-subscription__title">Envoie du mail ... non réussie</p>
            <p>Un problème technique empêche l'envoie de mail.</p>
            <p>N'hésitez pas à m'envoyer un mail : <a href="mailto:matthieu@barbares.co">matthieu@barbares.co</a></p>
        </div>
    `;
};

const is_resent_confirmation_link_success = () => {
    return `
        <div class="newsletter-subscription newsletter-subscription-confirmation-link-resent-with-success newsletter-subscription-response">
            ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
            <p class="newsletter-subscription__title">Un mail vous a été renvoyé !</p>
            <p>Il contient un nouveau lien de confirmation.</p>
            <p>N'hésitez pas à m'envoyer un mail : <a href="mailto:matthieu@barbares.co">matthieu@barbares.co</a></p>
        </div>
    `;
};

const subscription_step_1_already_added_and_not_confirmed = () => {
    return `
        <div class="newsletter-subscription newsletter-subscription-already-added-not-confirmed newsletter-subscription-response">
            ${get_block_in_waiting_of()}
            ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
            <p class="newsletter-subscription__title">Vous êtes déjà inscrit mais pas encore confirmé !</p>
            <button id="newsletter_subscription_already_added_not_confirmed__resend_confirmation_link" class="button like-a">Vous pouvez vous renvoyer un mail de confirmation en cliquant ici</button>
            <p>N'hésitez pas à m'envoyer un mail : <a href="mailto:matthieu@barbares.co">matthieu@barbares.co</a></p>
        </div>
    `;
};

const subscription_step_1_already_added = () => {
    return `
        <div class="newsletter-subscription newsletter-subscription-already-added newsletter-subscription-response">
            ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
            <p class="newsletter-subscription__title">Vous êtes déjà inscrit</p>
            <p>Et votre inscription est même confirmée !</p>
            <p>N'hésitez pas à m'envoyer un mail : <a href="mailto:matthieu@barbares.co">matthieu@barbares.co</a></p>
        </div>
    `;
};

const subscription_step1_error = () => {
    return `
        <div class="newsletter-subscription newsletter-subscription-error newsletter-subscription-response">
            ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
            <p class="newsletter-subscription__title">Inscription ... non réussie</p>
            <p>Un problème technique empêche votre inscription.</p>
            <p>N'hésitez pas à m'envoyer un mail : <a href="mailto:matthieu@barbares.co">matthieu@barbares.co</a></p>
        </div>
    `;
}

const subscription_step1_success = () => {
    return `
        <div class="newsletter-subscription newsletter-subscription-success newsletter-subscription-response">
            ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
            <p class="newsletter-subscription__title">Inscription réussie !</p>
            <p>Afin de finaliser votre inscription, confirmer votre adresse via l'e-mail reçu dans votre boîte mail.</p>
            <p>Si vous ne le trouvez point, peut-être est-il dans vos spams.</p>
        </div>
    `;
};

const subscription_step2_success = () => {
    return `
        <div class="newsletter-subscription newsletter-subscription-confirmation-success newsletter-subscription-response">
            ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
            <p class="newsletter-subscription__title">Inscription confirmée !</p>
            <p>Vous recevrez les prochaines mises à jour sur l'e-mail renseigné.</p>
            <p>La désinscription sera possible via la newsletter.</p>
        </div>
    `;
};

const newsletter_form = () => {
    return `
    <div id="newsletter_subscription" class="newsletter-subscription">
        ${get_block_in_waiting_of()}
        ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'newsletter_subscription__close_button'
    }, 'img')}
        <p class="newsletter-subscription__title">Abonnez-vous aux mises à jour de ma page</p>
        <p id="newsletter_subscription__error_message" class="newsletter-subscription__error-message">Remplissez bien le mail et cocher la case</p>
        <form class="newsletter-subscription__form" action="" method="post">
            <input id="newsletter_subscription__form__firstname" tabindex="1" type="text" name="firstname" placeholder="Votre Prénom">
            <input id="newsletter_subscription__form__lastname" tabindex="2" type="text" name="lastname" placeholder="Nom">
            <input id="newsletter_subscription__form__email" tabindex="3" type="email" name="email" placeholder="E-mail*" required>
            <div class="newsletter-subscription__form__subscription-confirmation">
                <input name="confirmation" tabindex="4" id="newsletter_subscription__form__confirmation" type="checkbox" value="y" required>
                <label for="newsletter_subscription__form__confirmation">
                    * Vous acceptez que je collecte et utilise vos données renseignées dans ce formulaire, dans le but de vous prévenir des futures mises à jour de ce CV.
                    Vos données seront enregistrées dans <a rel="nofollow" target="_blank" href="${Config_view.get().mailjet_url}">Mailjet ${get_external_link()}</a>
                </label> 
            </div>
            <input id="newsletter_subscription__form__submit" tabindex="5" type="submit" name="Je m'abonne">
        </form>
    </div>
    `;
};

export {
    newsletter_form,
    subscription_step1_success,
    subscription_step1_error,
    subscription_step2_success,
    subscription_step_1_already_added,
    subscription_step_1_already_added_and_not_confirmed,
    is_resent_confirmation_link_success,
    is_resent_confirmation_link_error
};