import {Data} from 'hind/core';

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class Requests {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw "Requests - constructor - Cannot construct singleton"; else {
            // console.debug("Requests - constructor");

            this._url = null;
        }
    }

    static get_instance() {
        if (!this[singleton]) {
            this[singleton] = new Requests(singletonEnforcer);
        }

        return this[singleton];
    }

    init(config) {
        return new Promise((resolve, reject) => {
            const config_app = Data.get_data('config_app');

            this._url = `${config_app.protocol_app}://${config_app.dns_app}${config_app.port_app}/`;
            this._main_endpoint = config.main_endpoint;
            this._route_to_get_token = config.route_to_get_token;

            resolve(true);
        });
    }

    send(method, route_uri, body = null) {
        return new Promise(
            (resolve, reject) => {
                this.get_token(route_uri).then(
                    token => {
                        this.send_query({
                            method   : method,
                            route_uri: route_uri,
                            token    : token,
                            body     : body
                        }).then(
                            res => {
                                resolve(res);
                            },
                            err => {
                                reject(err);
                            }
                        );
                    },
                    err => {
                        reject(err);
                    }
                );
            }
        );
    }

    xhr_process(method, url, body, headers = null, async = true) {
        return new Promise(
            (resolve, reject) => {
                function xhrCallback_response() {
                    if (xhr.status === 200 || xhr.status < 400) {
                        const headers = xhr.getAllResponseHeaders().trim().split(/[\r\n]+/);

                        let headerMap = {};

                        headers.forEach(function (line) {
                            const parts = line.split(': ');
                            const header = parts.shift();
                            const value = parts.join(': ');

                            headerMap[header] = value;
                        });

                        resolve({
                            response_text: xhr.responseText,
                            headers      : headerMap,
                            status         : xhr.status
                        });
                    } else {
                        const response = {
                            code   : xhr.status,
                            message: xhr.responseText
                        }
                        xhr = null;

                        reject(response);
                    }
                }

                let xhr = new XMLHttpRequest();

                xhr.open(method, url, async);

                if (headers !== null) {
                    for (const [name, value] of Object.entries(headers)) {
                        xhr.setRequestHeader(name, value);
                    }
                }

                xhr.onload = xhrCallback_response;

                xhr.send(body);
            }
        );
    }

    get_token(route_uri) {
        return new Promise(
            (resolve, reject) => {
                this.xhr_process('POST', `${this._url}${this._main_endpoint}/${this._route_to_get_token}`, `ru=${route_uri}`).then(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
        );
    }

    send_query(args) {
        return new Promise(
            (resolve, reject) => {
                this.xhr_process(
                    args.method,
                    `${this._url}${args.route_uri}`,
                    JSON.stringify(args.body),
                    {
                        Authorization: `Bearer ${args.token}`
                    }).then(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
        );
    }

    destroy() {
    }
}