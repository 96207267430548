export default function template() {
    return `
                <html>
                
                    <head>
                    
                         <meta name="description" content="" />
                    
                        <title>Maintenance</title>
                    
                    </head>
                    
                    <body data-current_page="maintenance">
                    
                        <main data-current_template="maintenance">
                        
                            <h1>Site en maintenance</h1>
                        
                        </main>
                    
                    </body>
                
                </html>
            `;
}