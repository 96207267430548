import {Di} from 'hind/core';
import Confirm_subscription from "../Modules/API/Routes/api/mailjet/v1/confirm_subscription";

export default class home {
    constructor(query_args) {
        this._query_args = query_args;
    }

    get() {
        return new Promise(
            (resolve, reject) => {
                if (typeof nodejs_protocol === 'undefined') {
                    resolve({
                        is_subscription_confirmation_succeed: false
                    });
                } else {
                    if (this._query_args.uri_info.uri_param !== null && this._query_args.uri_info.uri_param['k'] && this._query_args.uri_info.uri_param['k'].charAt(0) !== "") {
                        this.do_confirm_subscription(this._query_args.uri_info.uri_param).then(
                            res => {
                                resolve({
                                    is_subscription_confirmation_succeed: true
                                });
                            },
                            err => {
                                resolve({
                                    is_subscription_confirmation_succeed: false
                                });
                                // console.error(err);
                            }
                        );
                    } else {
                        resolve({
                            is_subscription_confirmation_succeed: false
                        });
                    }
                }
            }
        );
    }

    do_resend_confirmation_link(email) {
        let Oajax_requests = Di.get_di('AJAX_api');

        return new Promise(
            (resolve, reject) => {
                Oajax_requests.send('POST', '/api/mailjet/v1/email', {
                        "action": "resend_confirmation_link",
                        "email" : email
                    }
                )
                    .then(
                        res => {
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        }
                    ).finally(() => {
                    Oajax_requests = null;
                });
            }
        );
    }

    do_confirm_subscription(confirm_key_subscription) {
        let Oconfirm_subscription = new Confirm_subscription();

        return new Promise(
            (resolve, reject) => {
                Oconfirm_subscription.POST(null, confirm_key_subscription).then(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    }
                ).finally(() => {
                    Oconfirm_subscription = null;
                });
            }
        );
    }

    add_contact(contact) {
        let Oajax_requests = Di.get_di('AJAX_api');

        return new Promise(
            (resolve, reject) => {
                Oajax_requests.send('POST', '/api/mailjet/v1/contact', {
                        "Name"      : "New contact for my CV",
                        "Email"     : contact.email,
                        "Properties": {
                            "firstname": contact.firstname,
                            "lastname" : contact.lastname
                        }
                    }
                )
                    .then(
                        res => {
                            resolve(res);
                        },
                        err => {
                            reject(err);
                        }
                    ).finally(() => {
                    Oajax_requests = null;
                });
            }
        );
    }

    destroy() {
    }
}