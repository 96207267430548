let is_legal_notice_enabled = false;

export default class View {
    static prevent_scrollable_body() {
        document.body.classList.add("home--no-scrollable");
    }

    static do_scrollable_body() {
        document.body.classList.remove("home--no-scrollable");
    }

    static do_toggle_layer() {
        document.getElementById("layer").classList.toggle("layer--is-enabled");
    }

    static toggle_legal_notice() {
        document.getElementById("legal_notice").classList.toggle("legal-notice--is-open");
    }

    static open_legal_notice() {
        is_legal_notice_enabled = true;

        View.do_toggle_layer();
        View.toggle_legal_notice();
    }

    static close_legal_notice() {
        if (is_legal_notice_enabled) {
            View.toggle_legal_notice();
            View.do_toggle_layer();

            is_legal_notice_enabled = false;
        }
    }
}