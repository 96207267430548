//import {Data, Omodels} from "hind/core";

export default class Init {
    load_shared_data() {
        return new Promise(
            (resolve, reject) => {
                resolve(true);
                // Exemple With WORDPRESS API
                /* let Owp_option = Omodels.get_from_driver("Wordpress", "Option");
                 let Owp_menus = Omodels.get_from_driver("Wordpress", "Menus");

                 Promise.all([Owp_option, Owp_menus]).then(
                     values => {
                         Owp_option = values[0];
                         Owp_menus = values[1];

                         Owp_option.load_config("wp");
                         Owp_menus.load_config("wp");

                         const blogname = Owp_option.get("blogname");
                         const default_meta_description = Owp_option.get("custom_option_seo_default_meta_description");
                         const menus = Owp_menus.get();

                         Promise.all([blogname, default_meta_description, menus]).then(
                             values => {
                                 const blogname = values[0];
                                 const default_meta_description = values[1];
                                 const menus = values[2];

                                 Data.set_data("main_title", blogname);
                                 Data.set_data("default_meta_description", default_meta_description);
                                 Data.set_data("menus", menus);

                                 Owp_option = null;
                                 Owp_menus = null;

                                 resolve(true);
                             },
                             reason => {
                                 Owp_option = null;
                                 Owp_menus = null;

                                 reject(reason);
                             }
                         );
                     },
                     reason => {
                         reject(reason);
                     }
                 );*/
            }
        );
    }

    load_back_data() {
        return new Promise(
            (resolve, reject) => {
                resolve(true);
            }
        );
    }

    load_front_data() {
        return new Promise(
            (resolve, reject) => {
                resolve(true);
            }
        );
    }

    destroy() {

    }
}