import {Route, Ohelper_string} from "hind/core";
import home from "../views/routes/home";
import * as newsletter from "../views/newsletter/index";
import View from "../Controllers/View";

const config = {
    use_model     : true,
    do_get_request: true
};

export default class Home extends Route {
    constructor(args) {
        args = {
            Ourl_info: args.Ourl_info
        };

        super(args, config);

        this._newsletter_popin_is_open = false;
    }

    get_content_data() {
        return {
            view: home({
                popin_to_print: (this._json.is_subscription_confirmation_succeed) ?
                    "subscription_step2_success"
                    :
                    ""
            })
        };
    }

    get_content_data_first_loading_from_client() {
        return {
            selector: '#home-content'
        };
    }

    run_after_rendered_view() {
        this.do_toggle_menu();

        document.getElementById("home__menu__newsletter").addEventListener('click', (evt) => {
            if (!this._newsletter_popin_is_open) {
                this._newsletter_popin_is_open = true;
                this.print_newsletter_form();
                this.run_newsletter_global_listeners();
                this.run_newsletter_form_listeners();
            }
        }, false);

        if (document.getElementById("newsletter_subscription_wrapper").dataset.popinToPrint === "subscription_step2_success") {
            this.do_confirm_subscription();
        }
    }

    do_confirm_subscription() {
        this._newsletter_popin_is_open = true;

        View.prevent_scrollable_body();
        View.do_toggle_layer();

        document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.subscription_step2_success();

        this.run_newsletter_global_listeners();
    }

    print_newsletter_form() {
        View.do_toggle_layer();
        View.prevent_scrollable_body();

        document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.newsletter_form();
        document.querySelector("#newsletter_subscription_wrapper .newsletter-subscription").classList.add("newsletter-subscription--is-enabled");

        document.getElementById("newsletter_subscription__form__firstname").focus();
    }

    run_newsletter_global_listeners() {
        document.getElementById("newsletter_subscription__close_button").addEventListener("click", (evt) => {
            if (this._newsletter_popin_is_open) {
                this._newsletter_popin_is_open = false;
                document.getElementById("newsletter_subscription_wrapper").innerHTML = "";
                View.do_toggle_layer();
                View.do_scrollable_body();
            }
        }, false);
    }

    toggle_newsletter_spinner() {
        document.getElementById("newsletter_layer").classList.toggle("newsletter-layer--is-enabled");
        document.getElementById("newsletter_spinner").classList.toggle("newsletter-spinner--is-enabled")
    }

    run_newsletter_form_listeners() {
        document.getElementById("newsletter_subscription__form__submit").addEventListener("click", (evt) => {
            evt.stopPropagation();
            evt.preventDefault();

            this.toggle_newsletter_spinner();
            this.submit();

            return false;
        }, false);
    }

    submit() {
        const contact_data = {
            firstname   : Ohelper_string.get_html_entities_encoded(document.getElementById("newsletter_subscription__form__firstname").value),
            lastname    : Ohelper_string.get_html_entities_encoded(document.getElementById("newsletter_subscription__form__lastname").value),
            email       : document.getElementById("newsletter_subscription__form__email").value,
            confirmation: document.getElementById("newsletter_subscription__form__confirmation").checked
        };

        if (this.is_form_validated(contact_data)) {
            document.getElementById("newsletter_subscription__error_message").classList.remove("newsletter-subscription__error-message--is-enabled");

            this._Omodel.add_contact(contact_data).then(
                res => {
                    this.print_message_after_subscription(res.status, contact_data);
                },
                err => {
                    this.print_message_after_subscription(500);
                    console.error(err);
                }
            );
        } else {
            document.getElementById("newsletter_subscription__error_message").classList.remove("newsletter-subscription__error-message--is-enabled");
            void document.getElementById("newsletter_subscription__error_message").offsetWidth;
            document.getElementById("newsletter_subscription__error_message").classList.add("newsletter-subscription__error-message--is-enabled");

            this.toggle_newsletter_spinner();
        }
    }

    print_message_after_subscription(status, contact_data) {
        if (status === 200) {
            document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.subscription_step1_success();
        } else if (status === 210) {
            document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.subscription_step_1_already_added();
        } else if (status === 208) {
            let is_confirmation_link_sending = false;
            document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.subscription_step_1_already_added_and_not_confirmed();

            document.getElementById("newsletter_subscription_already_added_not_confirmed__resend_confirmation_link").addEventListener("click", (evt) => {
                if (!is_confirmation_link_sending) {
                    is_confirmation_link_sending = true;

                    this.toggle_newsletter_spinner();
                    this._Omodel.do_resend_confirmation_link(contact_data.email).then(
                        res => {
                            document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.is_resent_confirmation_link_success();
                        },
                        err => {
                            document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.is_resent_confirmation_link_error();

                            console.error(err);
                        }
                    ).finally(() => {
                        this.run_newsletter_global_listeners();
                    });
                }
            }, false);
        } else {
            document.getElementById("newsletter_subscription_wrapper").innerHTML = newsletter.subscription_step1_error();
        }

        this.run_newsletter_global_listeners();
    }

    is_form_validated(contact_data) {
        let is_valid = true;

        const fields_to_test = {
            email       : "input",
            confirmation: "checkbox"
        };

        for (let field in fields_to_test) {
            const type_of_value = field;
            const value = contact_data[type_of_value];

            if (fields_to_test[field] === "input" || fields_to_test[field] === "textarea") {
                if (value.charAt(0) === "") {
                    is_valid = false;
                } else {
                    if (type_of_value === 'email') {
                        const filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

                        if (!filter.test(value)) {
                            is_valid = false;
                        }
                    }
                }
            } else if (fields_to_test[field] === "checkbox") {
                if (!value) {
                    is_valid = false;
                }
            }
        }

        return is_valid;
    }

    do_toggle_menu() {
        if (document.getElementById('home_menu_button')) {
            document.getElementById('home_menu_button').addEventListener('click', function () {
                document.getElementById('home_menu').classList.toggle('home__menu--is-open');
                document.getElementById('home_menu_button').classList.toggle('home-menu-button--is-open');
            }, false);
        }
    }
}