/**
 * FAIRE UN MODULE CRYPTO => se baser sur le travail fait dans le projet Oauth server
 */

export default class Crypto {
    constructor() {
        this._Mcrypto = require('crypto');
        this._key = Buffer.from("yUhpTukhwgFHZCtav4hPnKGetpLfz64g");
        this._algorithm = "aes-256-cbc";
    }

    get_encrypted_value(text) {
        const iv = this._Mcrypto.randomBytes(16);
        const cipher = this._Mcrypto.createCipheriv(this._algorithm, this._key, iv);

        let encrypted = cipher.update(text);

        encrypted = Buffer.concat([encrypted, cipher.final()]);

        return iv.toString('hex') + ':' + encrypted.toString('hex');
    }

    get_decrypted_value(text) {
        const text_parts = text.split(":");
        const iv = Buffer.from(text_parts.shift(), 'hex');
        const encrypted_text = Buffer.from(text_parts.join(':'), 'hex');
        const decipher = this._Mcrypto.createDecipheriv(this._algorithm, this._key, iv);

        let decrypted = decipher.update(encrypted_text);

        decrypted = Buffer.concat([decrypted, decipher.final()]);

        return decrypted.toString();
    }
}