import {Ohelper_view_assets as assets} from "hind/core";

export default function footer(data) {
    return `
    <footer class="footer">
        <hr>
    
        <p>Matthieu BARBARESCO © 2024. Tous droits réservés</p>      
        
        <div class="footer__links">            
            <button id="footer__links__legal_notice" class="like-a">Mentions légales</button>
        </div>
    </footer>
   `;
}