import {Di} from 'hind/core';
import Crypto from "../../../../Crypto";

export default class Confirm_subscription {
    POST(request, confirm_key_subscription_data) {
        let Ocrypto = new Crypto();

        return new Promise(
            (resolve, reject) => {
                try {
                    const email = Ocrypto.get_decrypted_value(confirm_key_subscription_data['k']);

                    if (email !== "undefined") {
                        Di.get_di("Mailjet").contact_data().get(email).then(
                            res => {
                                let is_confirmed = true;

                                res.Data[0].Data.forEach((property) => {
                                    if (property.Name === "is_confirmed") {
                                        is_confirmed = (property.Value === "true");
                                    }
                                });

                                if (is_confirmed) {
                                    reject({
                                        code   : 404,
                                        message: "already confirmed"
                                    });
                                } else {
                                    Di.get_di('Mailjet').contact_data().put(email, [
                                        {
                                            "Name" : "confirm_key_subscription",
                                            "Value": ""
                                        },
                                        {
                                            "Name" : "is_confirmed",
                                            "Value": true
                                        }
                                    ]).then(
                                        res => {
                                            resolve(res);
                                        },
                                        err => {
                                            reject({
                                                code   : err.statusCode,
                                                message: err.stack
                                            });
                                        }
                                    ).finally(() => {
                                        Ocrypto = null;
                                    });
                                }
                            },
                            err => {
                                reject({
                                    code   : err.statusCode,
                                    message: err.stack
                                });
                            }
                        ).finally(() => {
                            Ocrypto = null;
                        });
                    } else {
                        reject({
                            code   : 404,
                            message: "wrong key"
                        });
                    }
                } catch (e) {
                    reject({
                        code   : 404,
                        message: "wrong key"
                    });
                } finally {
                    Ocrypto = null;
                }
            }
        );
    }
}