import footer from "./footer";
import {Ohelper_view_assets as assets, config_app} from "hind/core";
import {Config_view} from "hind//workspace";
import {get_external_link} from "./utils/icon";

export default function base(data) {
    return `
        <!DOCTYPE html>
        
        <html lang="fr">
        
            <head>
            
                <meta name="description" content="${data.meta.description}" />
            
                <title>${data.title}</title>
                
                ${assets.get(data.styles, "css")}

                <meta charset="UTF-8" />

                <meta name="viewport" content="initial-scale=1,maximum-scale=1,user-scalable=no" />

            </head>
            
            <body class="${data.current_page}" data-current_page="${data.current_page}" data-current_template="${data.template_name}">
                <main>
                    <div id="layer" class="layer"></div>
                    <div class="container" id="container" data-current_template="${data.template_name}">
                        ${data.view}
                    </div>
                </main>
                
                <section id="legal_notice" class="legal-notice">
                    ${assets.get({
        name : 'xmark-solid.svg',
        class: 'newsletter-subscription__close-button',
        id   : 'legal_notice__close_button'
    }, 'img')}
                    <h1>Mentions légales</h1>
                    <p>Éditeur du site & responsable légale : Matthieu BARBARESCO</p>
                    <p>Raison Sociale : Matthieu Barbaresco</p>
                    <p>SIREN : 818 804 601</p>
                    <p>${Config_view.get().my_address} - FRANCE</p>
                    <p><a href="mailto:${Config_view.get().my_email}">${Config_view.get().my_email}</a></p>
                    <p><a href="tel:${Config_view.get().my_phone_link}">${Config_view.get().my_phone_label}</a></p>
                    <hr>
                    <p>Hébergeur du site : <a href="https://www.ovhcloud.com/fr/" target="_blank" rel="nofollow">OVH SAS ${get_external_link()}</a>, 2 rue Kellermann - 59100 Roubaix - FRANCE</p>
                    <p></p>
                    <p>Gestion des contacts pour la newsletter : <a rel="nofollow" target="_blank" href="${Config_view.get().mailjet_url}"> MAILJET ${get_external_link()}</a></p>
                    <p>Siège social de MAILJET : 4 rue Jules Lefebvre - 75009 Paris - FRANCE</p>
                    <p>Vos données pour la newsletter sont enregistrés chez MAILJET.</p>
                    <p>Vous pouvez vous en désinscrire via une de mes newsletters ou sur simple demande qui me serait adressée.</p>
                </section>
              
                ${footer()}
              
                ${assets.get(data.scripts, 'js')}
        
            </body>
        </html>
    `;

};