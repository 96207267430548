import {Ohelper_view_assets as assets, config_app} from "hind/core";
import {Config_view} from "hind//workspace";
import {get_external_link, get_duration_block} from "../utils/icon";

export default function home(data) {
    return `
        <div id="newsletter_subscription_wrapper" data-popin-to-print="${data.popin_to_print}"></div>
        <nav id="home_menu" class="home__menu">
             <p class="home__menu__coordinates">
                <a href="#coordinates">${assets.get('user-solid.svg', 'img')}</a>
            </p>
             <p class="home__menu__typologies">
                <a href="#job_typologies">${assets.get('palette-solid.svg', 'img')}</a>
            </p>
             <p class="home__menu__skills">
                <a href="#skills">${assets.get('brain-solid.svg', 'img')}</a>
            </p>
             <p>
                <a href="#perso_projects">${assets.get('lightbulb-solid.svg', 'img')}</a>
            </p>
            <p class="home__menu__asso-projects">
                <a href="#asso_projects">${assets.get('circle-nodes-solid.svg', 'img')}</a>
            </p>
             <p class="home__menu__freelance-xp">
                <a href="#freelance_xp">${assets.get('person-walking-luggage-solid.svg', 'img')}</a>
            </p>
             <p class="home__menu__employees-xp">
                <a href="#employee_xp">${assets.get('user-tie-solid.svg', 'img')}</a>
            </p>
            <p class="home__menu__employees-xp">
                <a href="#trainings">${assets.get('user-graduate-solid.svg', 'img')}</a>
            </p>
            <p class="homme__menu__perso-activities">
                <a href="#perso_activities">${assets.get('gem-regular.svg', 'img')}</a>
            </p>
            <p id="home__menu__newsletter" class="home__menu__newsletter">
                ${assets.get('envelope-solid.svg', 'img')}
            </p>
        </nav>
        <div id="home_menu_button" class="home-menu-button">
             <div id="menu-toggle" class="cross">
                <span></span>
             </div>
        </div>
        
        <section id="home-content">
            <div id="coordinates" class="coordinates">
                <div>
                    <div>
                        <h1>Matthieu BARBARESCO</h1>
                        <p class="rm-md">&nbsp;</p>
                        <p>${Config_view.get().my_address}</p>
                        <p><a class="external_link" href="tel:${Config_view.get().my_phone_link}">${Config_view.get().my_phone_label}</a></p> 
                        <p><a class="external_link" href="mailto:${Config_view.get().my_email}">${Config_view.get().my_email}</a></p>
                        <p class="coordinates__additionals-links">                        
                            <a class="external_link" target="_blank" rel="nofollow" href="https://www.linkedin.com/in/matthieu-barbaresco-14956b37/">${assets.get({
        name : "linkedin.svg",
        alt  : "Lien Linkedin",
        class: "coordinates__linkedin-ico"
    }, "img")}</a><a class="external_link" target="_blank" href="${config_app.url_documents}d8KF3k_CV_MATTHIEU_BARBARESCO.pdf">${assets.get({
        name : "file-pdf-solid.svg",
        alt  : "Lien CV en PDF",
        class: "coordinates__pdf-ico"
    }, "img")}
                            </a>
                        </p>  
                    </div>
                    <div class="my-photo">
                        ${assets.get({
        name: "my-photo.png",
        alt : "Photo de Matthieu Barbaresco"
    }, "img")}
                    </div>
                </div>
            </div>
            <div class="titles">
                <strong>Conception API / BDD</strong>
                <strong>Développement PHP / JavaScript</strong>
                <strong>Pourvoyeur de conseils / non donneur de leçons</strong>
            </div>
            <article id="job_typologies" class="job-typologies">
                <div>
                    <h2>Typologie des interventions</h2>
                    <ul>
                        <li>
                            Gestion d’un projet web (fonctionnel et technique), encadrement technique, formation,
            recrutement de free-lance.
                        </li>
                        <li>
                            Modélisation de données, conception NoSQL / SQL / API REST / applicative, wireframing.
                        </li>
                        <li>
                            Microservice sur AWS LAMBDA.
                        </li>
                        <li>
                            WordPress : e-commerce, espace privé, thème, plugin, API, composants Gutenberg.
                        </li>
                        <li>
                            TDD avec PHP UNIT.
                        </li>
                        <li>
                            Installation et sécurisation environnement LAMP, NodeJS, NGINX.
                        </li>
                        <li>
                            Maintenance applicative.
                        </li>
                        <li class="firefighter">
                            Pompier / Commando.
                        </li>
                    </ul>
                </div>
            </article>
            <article id="skills">
                <div>
                    <h2>Connaissances techniques</h2>
                    
                    <div class="technicals_skills__grid">
                        <div>
                            <h3>Systèmes</h3>
                            
                            <ul>
                                <li>Debian</li>
                            </ul>
                        </div>
                        <div>
                            <h3>Languages</h3>
        
                            <ul>
                                <li>PHP</li>
                                <li>JavaScript ES</li>
                                <li>SQL</li>
                                <li>SHELL linux</li>
                                <li>CSS</li>
                                <li>SASS</li>
                            </ul>
                        </div>
                        <div>
                            <h3>CMS</h3>
                            
                            <ul>
                                <li>WordPress</li>
                            </ul>
                        </div>
                        <div>
                            <h3>Serveurs, services</h3>
                            
                            <ul>
                                <li>LAMP</li>
                                <li>LEMP</li>
                                <li>NodeJS</li>
                                <li>Caddy</li>
                            </ul>
                        </div>
                        <div>
                            <h3>SGBD</h3>
                            
                            <ul>
                                <li>MariaDB</li>
                                <li>MySQL</li>
                                <li>MongoDB</li>
                                <li>DynamoDB</li>
                            </ul>
                        </div>
                        <div>
                            <h3>Logiciels</h3>
                            
                            <ul>
                                <li>PHPStorm</li>
                                <li>Slack</li>
                                <li>Jira</li>
                                <li>Confluence</li>
                                <li>Postman</li>
                                <li>Gogs</li>
                                <li>FramaGit</li>
                                <li>GitLab</li>
                                <li>GitHub</li>
                            </ul>
                        </div>
                        <div>
                            <h3>Outils divers & variés</h3>
                            
                            <ul>
                                <li>GIT</li>
                                <li>WebPack</li>
                                <li>Vite</li>
                                <li>NPM</li>
                                <li>NPX</li>
                                <li>Composer</li>
                                <li>Let's encrypt</li>
                                <li>AWS Lambda</li>
                                <li>Supervisord daemon</li>
                                <li>PM2</li>    
                            </ul>
                        </div>
                        <div>
                            <h3>Frameworks, librairies</h3>
                            
                            <ul>
                                <li>Serverless</li>
                                <li>Express JS</li>
                                <li>Phalcon</li>
                                <li>React</li>        
                            </ul>
                        </div>
                        <div>
                            <h3>Paiement, mail</h3>
                            
                            <ul>
                                <li>Stripe</li>
                                <li>Mailjet</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </article>
            <article class="langs">
                <h2>Langue</h2>
                <p>Anglais : Lu, parlé, écrit (A2)</p>
            </article>
            <div class="experiences">
                <article id="perso_projects" class="experiences__typeof">
                    <h2>Projet personnel pour une utilisation professionnelle</h2>
                        <section class="experiences__experience">
                        
                        <h3>HIND (Framework JS)</h3>
                        <p class="experiences__experience_tags">Mots-clés : <strong>JS ES6, NodeJS, Webpack, SASS, API REST, NPX, GitHub</strong></p>
                        <p>Création d'un framework isomorphe en MVP et codé en JavaScript ES6 (Local Forage / Promise / NodeJS / Babel /
            Webpack) pour simplifier le développement d’application full JavaScript :</p>
            
                        <ul>
                            <li>Une couche applicative, un service d’injection de dépendances.</li>
                            <li>Création d’un gestionnaire de plugins (breadcrumb / ecommerce / …).</li>
                            <li>Extension pour écrire des routes de type API REST.</li>
                            <li>Orienté aussi pour l’UX et le design – Hooks synchronisés par Promise sur toutes les étapes
        de chargement de l’application, d’une route ou autre composant => liberté d’action complète
        sur les animations et les changements de vues.</li>
                            <li>Souplesse d’installation et de mise à jour via npm / npx.</li>
                            <li>Architecture SASS pour les styles.</li>
                        </ul>
                    </section>
                </article>
                <article id="asso_projects" class="experiences__typeof">
                    <h2>Conception et programmation - Bénévolat</h2>
                    
                    <section class="experiences__experience">
                        <h3><a target="_blank" class="external_link" href="https://permamontreuil.fr/">PermaMontreuil
                        ${get_external_link()}
                        </a></h3>
                        ${get_duration_block("10/2023 - L'aventure continue")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>WordPress, PHP, JS, CSS, React, formation WordPress</strong></p>
                        <p>Création d’un site web pour la communication de l’association PermaMontreuil.</p>
                        <p>Il présente des ressources sur la permaculture, ainsi que l’événementiel et les publications de
                        l’association.</p>
                        <p>Il permet de s’enregistrer en tant que bénévole, stand, animateur-ice d’atelier / animation pour "Les Estivales de la Permaculture", le festival dédiée à la permaculture lancé depuis 2014 par l'association.</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Concevoir et réaliser le site web. Le mettre en ligne et assurer sa maintenance applicative.</li>
                            <li>Proposer un paramétrage simple et fourni des événements (fréquence, dates, heures, organisateurs…).</li>
                            <li>Faciliter les inscriptions - et leurs traitements - au festival en tant que bénévole, stands, animateurs-ices d’ateliers.</li>
                            <li>Surcharge du thème « accelerate » via un thème enfant.</li>
                            <li>Développement : WordPress, JS, CSS, CRON JOB, librairie Calendar.js, React Js (composant Gutenberg).</li>
                            <li>Mise en production: Installation d’un serveur LAMP chez OVH.</li>
                            <li>Formation à l’utilisation de l’outil. Création d'un guide d'utilisation.</li>
                        </ul>
                    </section>
                </article>
                <article id="freelance_xp" class="experiences__typeof">
                    <h2>Expériences professionnelles – Auto-entrepreneur</h2>
                    
                    <section class="experiences__experience">
                        <h3>Yellow Korner</h3>
                        ${get_duration_block("01/2023 - 04/2023")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>React, PHP, Twig, EvQueue, Gogs, Mailchimp API</strong></p>
                        <p>Maintenance applicative du CRM de Yellow Korner.</p>
                        <p>Une conception maison et standardisée en accord avec les principes SOLID.</p>
                        <p>Des composants React rendent flexibles la conception en JS et facilitent l’expérience utilisateur (ainsi que l’UI) des
                        différentes interfaces. Twig est implémenté.
                        </p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Corriger et faire évoluer l’outil : ses interfaces, ses tâches automatisées, ses
                            fonctionnalités, ses appels à des API externes.
                            </li>
                            <li>Développement : PHP, Twig, React, SCSS, Vanilla JS.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3><a target="_blank" class="external_link" href="https://fotokino.org/">Fotokino
                        ${get_external_link()}</a></h3>
                        ${get_duration_block("07/2022 - 11/2022")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Gestion de projet, WordPress, JavaScript, Webpack, Git</strong></p>
                        <p>Refondre la totalité du code du site de l’association « Fotokino».</p>
                        <p>C'est une organisation qui diffuse des travaux artistiques dans le champs des arts visuels, qui organise des ateliers,
                        des rencontres spécifiquement auprès des plus jeunes.
                        </p>
                        <p>La démarche est de contribuer à la sensibilisation du regard, à l’éveil de l’esprit critique.
                        </p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>
                                Développement du site en tandem avec Fewzi Raffed qui se charge du graphisme, de l’intégration et de la gestion client.
                            </li>
                            <li>Gestion du projet : Slack / Github.</li>
                            <li>Développement : PHP, WordPress, Webpack pour les SCSS.</li>
                            <li>Installation serveur préproduction : LAMP chez OVH.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Club Med</h3>
                        ${get_duration_block("02/2021 – 09/2022")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>WordPress, JavaScript, Webpack, Git, Docker</strong></p>
                        <p>Faire évoluer et maintenir l’application « CMTA Travel Agents ». Un site de gestion des réservations à destination des agences partenaires du Club Med.</p>
                        <p>Participer à l’amélioration de la gestion des développements dans GIT, pour mieux organiser les différents MERGE, REBASE et déploiements / rollbacks.
                        </p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>
                                Développement du site au sein d’une équipe de 3 fonctionnels et d’un développeur en début de mission. Quelques mois plus tard, nous étions 4 développeurs dont un lead dev.
                            </li>
                            <li>Gestion de projet : Teams / Figma / Github.</li>
                            <li>Développement : PHP, WordPress, JavaScript, création d’un projet WebPack pour les SASS et JS</li>
                            <li>En charge de la documentation technique.</li>
                            <li>Garant de la qualité des codes produits en tandem avec le lead dev.</li>
                            <li>Environnement technique: LEMP, Docker, NodeJS, Jenkins.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3><a target="_blank" class="external_link" href="https://quatuorpsophos.com/">Quatuor Psophos
                        ${get_external_link()}
                        </a></h3>
                        ${get_duration_block("12/2020 - 02/2021")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Gestion de projet, WordPress, JavaScript, Installation et sécurisation serveur LEMP et NodeJS, Webpack, PM2, GIT</strong></p>
                        <p>Refondre l’UI et le socle technique du site de l’association « Les Amis du Quatuor Psophos ».</p>
                        <p>C'est une association composée de 4 musiciens qui forment donc un quatuor à corde.</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>
                                Développement du site en tandem avec Fewzi Raffed qui se charge du graphisme et de l’intégration.
                            </li>
                            <li>Gestion de projet : Slack / Jira / Confluence / Github.</li>
                            <li>Développement : PHP, WordPress, interface en JavaScript sur un serveur NodeJS avec <a href="#perso_projects">le framework HIND.</a></li>
                            <li>Installation serveur : LAMP, LEMP et NodeJS chez OVH.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3><a target="_blank" class="external_link" href="https://bootstrap-label.com/">Bootstrap Label
                        ${get_external_link()}</a>, le WIPP Festival
                        </h3>
                        ${get_duration_block("08/2020 - 11/2020")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Recrutement freelance, Gestion de projet / d’équipe, WordPress</strong></p>
                        <p>Créer les logos et les sites (sur-mesure) de la société Bootstrap Label - Une maison de
                        production de documentaires dans le cinéma – et du WIPP : le festival de cinéma
                        spécialisé en métrage en cours d’écriture, organisé par cette même société.
                        </p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Accompagnement client et recrutement de deux profils en free-lance :
                            <p></p>
                            <ul>
                                <li>Une illustratrice et graphiste web / print pour le logo, la mise en page et le graphisme :
                                Mathilde Imbert.</li>
                                <li>Un graphiste et intégrateur : Fewzi Raffed.</li>
                            </ul>
                            </li>
                            <li>Analyse des données et conception : Deux administrations WordPress qui collent
    simplement au plus près des besoins. Le minimum nécessaire en plugins.</li>
                            <li>Gestion de projet : Slack / Jira / Confluence / Github / Adobe XD.</li>
                            <li>Développement : PHP, WordPress, JavaScript.</li>
                            <li>Installation serveur : LAMP chez Gandi.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Health Events
                        </h3>
                        ${get_duration_block("15/07/2020 - 10/08/2020")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Modélisation SQL, WordPress</strong></p>
                        <p>Refondre le système d'inscription à une session de formation de santé.
                        </p>
                        <p>Permettre le tracking des balises UTM au moment de l'inscription.</p>
                        <p>Remplir automatiquement une feuille « Google sheets ».</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Conception : BDD (SQL).</li>
                            <li>Développement : PHP, WordPress, JavaScript, Simple-DataTables.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Delphes Paris
                        </h3>
                        ${get_duration_block("01/2020 - 04/2020")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Conception applicative / API REST, WordPress, Stripe Payment, Installation et
    sécurisation serveur LAMP et NodeJS</strong></p>
                        <p>Refonte UX et technique d'une plateforme e-commerce de vente de bijoux.
                        </p>
                        <p>Il est impératif de garder l’administration WordPress avec la boutique woocommerce..</p>
                        <p>Le besoin du client est de proposer une navigation fluide, une mise en page claire, un
    tunnel d’achat rapide et facile à utiliser, un paiement en ligne tout aussi facile à utiliser –
    l’ancien système étant, de préférence, à abandonner.</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Conception : architecture applicative, conception API REST.</li>
                            <li>Développement : PHP, WordPress API, Stripe, interface en JavaScript avec <a href="#perso_projects">le framework HIND.</a></li>
                            <li>Installation serveur : LAMP et NodeJS chez Gandi.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Investment Design and Patrimony
                        </h3>
                        ${get_duration_block("2019 - 2020")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Modélisation SQL / NoSQL, WordPress, Stripe Payment, Installation et
    sécurisation serveur LAMP, Mailjet API</strong></p>
                        <p>Conception et développement d'un espace privé pour déclarer ses locations meublées, sur
    une instance Wordpress.
                        </p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Conception : BDD (SQL / NoSQL), UX (espace privé sur mesure).</li>
                            <li>Développement : PHP, WordPress, Stripe, JavaScript.</li>
                            <li>Installation serveur : LAMP sur public cloud OVH.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Simonnot Avocat
                        </h3>
                        ${get_duration_block("29/08/2019 - 19/12/2019")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Modélisation SQL / NoSQL, WordPress, installation et sécurisation serveur
    LAMP</strong></p>
                        <p>Conception et programmation d'une application web pour gérer le cycle de vie d'une
    affaire de divorce par consentement mutuel.
                        </p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Conception : BDD (SQL / NoSQL), UX (espace privé sur mesure).</li>
                            <li>Développement : PHP, WordPress, JavaScript.</li>
                            <li>Installation serveur : LAMP sur cloud Gandi.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Typology
                        </h3>
                        ${get_duration_block("07/01/2019 - 14/01/2019")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Micro-service PHP sur AWS Lambda</strong></p>
                        <p>Développement d’un micro service sur AWS Lambda.</p>
                        <p>Il réalise un certain nombre de
    fonctionnalités autour des codes et demandes d’invitations, ainsi que l’enregistrement de
    clients invités.
                        </p>
                        <p>Les informations sont envoyés par une boutique Shopify et traitées sur AWS.</p>
                        </p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Développement : PHP</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Kameleos
                        </h3>
                        ${get_duration_block("27/09/2018 - 09/10/2018")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Encadrement technique, NodeJS, intervention commando</strong></p>
                        <p>Évolutions et Debug sur la plateforme d’e-learning « Kameleos ».</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Encadrement des bonnes pratiques avec les deux développeurs juniors.</li>
                            <li>Développement : NodeJS, MongoDB.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Zeta Technologies
                        </h3>
                        ${get_duration_block("14/06/2018 - 26/06/2018")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Modélisation de données, conception NoSQL et API REST, architecture
    applicative, intervention commando</strong></p>
                        <p>Réaliser les bases d’un système de gestion des données de patients souffrant
    d’acouphènes. Ils réalisent des exercices afin de les « gommer ».</p>
                        <p>Ce système doit pouvoir enregistrer de manière souple les exercices et les résultats.</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Conception : NoSQL, REST API.</li>
                            <li>Développement : NodeJS, MongoDB.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>RedPill
                        </h3>
                        ${get_duration_block("04/04/2018 - 17/05/20188")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Conception DynamoDB et API REST, NodeJS, AWS LAMBDA, Configuration via
    Serverless</strong></p>
                        <p>Réaliser la base d’un système permettant de simuler selon divers calculs l’intérêt de
    souscrire ou non à l’assurance emprunteur du partenaire de mon client sur ce projet.</p>
                        <p>Le système prend aussi en charge l’inscription et la génération du contrat.</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Conception : NoSQL, REST API.</li>
                            <li>Développement : Api en JavaScript sur NodeJS, BDD DynamoDB. Le tout configuré via
    Serverless et envoyé sur AWS LAMBDA.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>EpressPack
                        </h3>
                        ${get_duration_block("2017 - 2018")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Formation, encadrement technique, thème WordPress, intervention pompier</strong></p>
                        <p>Venir en renfort sur la maintenance sur plugin contenant le système principal du produit de
    cet agence.</p>
                        <p>Refondre les développements WordPress pour les rendre plus maintenables et
    modulables.</p>
                        <p>Encadrer, former et aider toutes les équipes de développements.</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Encadrement : Former les développeurs juniors à la POO et au nouveau système de
    thème (sur mesure) technique WordPress. Les faire progresser en programmation et en
    algorythme. Documentation des composants PHP / JavaScript produits.</li>
                            <li>Conception : architecture de codes modulables, génériques. Séparation en composants.</li>
                            <li>Développement : PHP, WordPress, JavaScript.</li>
                        </ul>
                    </section>
                    <section class="experiences__experience">
                        <h3>Mahi Mahi
                        </h3>
                        ${get_duration_block("11/2017 - 01/2018")}
                        <p class="experiences__experience_tags">Mots-clés : <strong>Modélisation de données, conception API REST, WordPress API</strong></p>
                        <p>Concevoir une API pour piloter une application d’aide aux personnes âgées dans certains
    pays de l’Union Européenne.</p>
                        <p>L’application est un WordPress multi-site. Je viens donc concevoir une API venant
    surcharger celle de base intégrée dans ce CMS.</p>
                        <p class="bold">Rôles :</p>
                        <ul>
                            <li>Conception : REST API avec Postman. Analyse complète de la base de données de
    l’application (Modèle de données de BuddyPress) pour en retirer une API.</li>
                            <li>Développement : PHP, WordPress.</li>
                        </ul>
                    </section>
                </article>
                <article id="employee_xp" class="experiences__typeof experiences__typeof__employee">
                    <h2>Expériences professionnelles – Employé</h2>
                    
                    <section class="experiences__experience">
                        <h3>Jackson & Kent
                        </h3>
                        ${get_duration_block("2017")}
                        <p>Développement : PHP (Laravel, Phalcon, PHP UNIT), JavaScript ES, MongoDB.</p>
                    </section>
                    <section class="experiences__experience">
                        <h3>Awak'IT
                        </h3>
                        ${get_duration_block("2015 - 2016")}
                        <p>Conception : analyse de brief, analyse fonctionnelle, conseil technique, création de wireframes.</p>
                        <p>Encadrement : Veille technique, encadrement des développements sur WordPress, optimisations
    des architectures pour l’intégration continue avec Jenkins (création de build), suivi de l’évolution
    des développements en général.</p>
                        <p>Développement : PHP, WordPress, JavaScript.</p>
                    </section>
                    <section class="experiences__experience">
                        <h3>Altedis
                        </h3>
                        ${get_duration_block("2015")}
                        <p>Intégration & développement front-End : CSS, JavaScript.</p>
                    </section>
                    <section class="experiences__experience">
                        <h3>TimmXware
                        </h3>
                        ${get_duration_block("2014 - 2015")}
                        <p>Encadrement : Rédaction d’un document de recommandations sur les optimisations des codes et
    de la BDD, sécurisation d’une instance WordPress.</p>
                        <p>Conception : Modélisation UML (flux de données, diagramme d’action, scénarios), wireframes,
    analyse de brief / fonctionnelle, algorithmie.</p>
                        <p>Développement : PHP, WordPress, JavaScript.</p>
                    </section>
                    <section class="experiences__experience">
                        <h3>Alter Way
                        </h3>
                        ${get_duration_block("2012 - 2014")}
                        <p>Référencement : Netlinking, sitemap, plan de taggage, plan de tracking dans les newsletters
    suivies d’analyses des résultats et recommandations, recherche de mots-clés utiles pour tel
    projet.</p>
                        <p>Développement : PHP, WordPress, JavaScript, Twig (plugin Timber Twig sur WordPress), recette
    fonctionnelle et technique, LESS.</p>
                    </section>
                    <section class="experiences__experience">
                        <h3>Masha Design & Conseils
                        </h3>
                        ${get_duration_block("2010 - 2012")}
                        <p>Conseil et encadrement : Réunions de projets, recueil des besoins, conseil technique pour les
    clients.</p>
                        <p>Conception : architecture des codes, modélisation de données.</p>
                        <p>Développement : PHP, WordPress, JavaScript, CSS.</p>
                    </section>
                </article>
            </div>
            <article id="trainings">
                <h2>Formations</h2>
                
                <div class="trainings">
                    <div class="timeline">
                        <div class="timeline__item">
                              <div class="timeline__item__content">
                                <div class="timeline__item__content__timeline-date">2012</div>
                                <div class="timeline__item__content__timeline-description">Licence professionnelle ACSID - CNAM</div>
                              </div>
                            </div>
                            <div class="timeline__item">
                              <div class="timeline__item__content">
                                <div class="timeline__item__content__timeline-date">2010</div>
                                <div class="timeline__item__content__timeline-description">D.U.T informatique - Orsay</div>
                              </div>
                            </div>
                            <div class="timeline__item">
                              <div class="timeline__item__content">
                                <div class="timeline__item__content__timeline-date">2006</div>
                                <div class="timeline__item__content__timeline-description">BAC S.T.I. électronique</div>
                              </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article id="perso_activities">
                    <h2>Centres d'intérêts et activités personnelles</h2>
                    <div class="perso-activities">
                        <h3>Cinéma</h3>
                        <p>Intérêt pour le cinéma underground, d'exploitation et fantastique.</p>
                        <hr>
                        <h3>Musique</h3>
                        <p>10 ans de tuba et d'orchestre harmonique.</p>
                        <p>DJ – conception de mix.</p>
                        <hr>
                        <h3>Théâtre</h3>
                        <p>Improvisation : cours / ateliers et scènes ouvertes.</p>
                        <hr>
                        <h3>Photographie</h3>
                        <p>Portraits de gens dans la rue.</p>
                        <p>Paysages urbains.</p>
                        <p>Traitement numérique.</p>
                        <hr>
                        <h3>Associatif</h3>
                        <p>Propose des coups de main à des associations œuvrant sur ces terrains :
        cantines auto-gérées, agriculture.</p>
                    </div>
                </article>
            </section>
        `;
};