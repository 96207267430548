import {Route} from "hind/core";
import tpl_404 from "../views/routes/page_not_found";

const config = {
    template_name: "404",
    title: "Page non trouvée"
};

export default class Page_not_found extends Route {
    constructor(args) {
        args = {
            Ourl_info: args.Ourl_info
        };

        super(args, config);
    }

    get_content_data() {
        return {
            view: tpl_404()
        };
    }

    get_content_data_first_loading_from_client() {
        return {};
    }

    run_after_rendered_view() {

    }
}