//import test from "../views/test";

export default class Templates {
    static get() {
        return {
            location: {
                page_slug: {},
                route: {
                    /* contact: {
                           test: ""
                     }*/
                }
            },
            config: {
                /*  test: {
                      call_if_is_a_function: {test}
                  }*/
            }
        }
    }
}