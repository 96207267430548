export default class Url {
    static get() {
        return {
            rewriting: {
                /*'^[-A-Za-z0-9]{1,}/[-A-Za-z]{1,}/[0-9]{1,}$': [
                    {
                        localisation: '/[0-9]{1,}$',
                        property: "paged",
                        remove_from_url: false
                    }
                ]*/
            }
        }
    }
}